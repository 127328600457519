import { UiPopper, UiAvatar, UiIconTemplate, UiList, UiIconName, UiLink, UiListMenuItem } from '@frontend/ui-kit';
import { useHelp, useReleaseUpdates } from '~/components/header/useDocsLinks';
export default {
  __name: 'MobileMenu',
  props: {
    pages: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    var {
      link: updates
    } = useReleaseUpdates();
    var {
      link: help
    } = useHelp();
    var openByLocale = ref('');
    function toggle(locale) {
      openByLocale.value = locale !== openByLocale.value ? locale : '';
    }
    return {
      __sfc: true,
      updates,
      help,
      openByLocale,
      toggle,
      UiPopper,
      UiAvatar,
      UiIconTemplate,
      UiList,
      UiIconName,
      UiLink,
      UiListMenuItem,
      useHelp,
      useReleaseUpdates
    };
  }
};