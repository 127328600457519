import "core-js/modules/es.array.includes.js";
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2';
import useSettingsStore from '~/stores/settingsStore';
import useUserStore from '~/stores/userStore';
export default defineNuxtPlugin(nuxtApp => {
  var {
    app,
    $pinia
  } = nuxtApp.nuxt2Context;
  $pinia.use(createPersistedStatePlugin({
    filter: mutation => {
      return !['branding', 'app'].includes(mutation.storeId);
    }
  }));
  app.router.onReady(() => {
    var {
      initSettings,
      initBranding
    } = useSettingsStore();
    var {
      loggedIn
    } = useUserStore();
    if (loggedIn) {
      return initSettings();
    }
    return initBranding(true);
  });
});