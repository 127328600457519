import _asyncToGenerator from "@babel/runtime/helpers/esm/asyncToGenerator";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineStore } from 'pinia';
import { ref, computed } from 'vue-demi';
import { fromArray } from '@frontend/ui-kit';
import { useApi } from '~/plugins/api';
import { loginRouter } from '~/assets/helpers/routers';
import { removeCookie, setCookie } from '~/assets/utils/cookie';
import { useKeycloak } from '~/assets/utils/useKeycloak';
export var AccessRole;
(function (AccessRole) {
  AccessRole["Admin"] = "admin";
  AccessRole["Auditor"] = "auditor";
  AccessRole["Supervisor"] = "supervisor";
  AccessRole["SeniorEditor"] = "senioreditor";
  AccessRole["Support"] = "support";
  AccessRole["DataAdmin"] = "data admin";
  AccessRole["Editor"] = "editor";
  AccessRole["Merch"] = "merch";
  AccessRole["Operator"] = "operator";
  AccessRole["Test"] = "test";
  AccessRole["Billing"] = "billing";
})(AccessRole || (AccessRole = {}));
var TokenPrefix = 'Bearer ';
export var PingCookie = 'token';
export var InternalCookie = 'auth._token.local';
var AuthToken = 'auth-token';
var useUserStore = defineStore('userStore', () => {
  var token = ref('');
  var user = ref();
  var loggedIn = computed(() => !!token.value);
  function setUserToken(newToken) {
    token.value = TokenPrefix + newToken;
    setCookie(InternalCookie, token.value);
    setCookie(AuthToken, newToken);
  }
  var {
    authSSO,
    authLogoutSSO,
    ssoLoading,
    sso,
    updateTokenSSO
  } = useKeycloak({
    setUserToken,
    tokenSSO: getCleanToken()
  });
  function getUserToken() {
    return token.value;
  }
  function getCleanToken() {
    return getUserToken().replace(TokenPrefix, '');
  }
  function fetchUser() {
    return _fetchUser.apply(this, arguments);
  }
  function _fetchUser() {
    _fetchUser = _asyncToGenerator(function* () {
      if (loggedIn.value) {
        user.value = yield useApi().auth.me();
      }
    });
    return _fetchUser.apply(this, arguments);
  }
  function logout() {
    return _logout.apply(this, arguments);
  }
  function _logout() {
    _logout = _asyncToGenerator(function* () {
      var ctx = useNuxtApp().nuxt2Context;
      var {
        route
      } = ctx;
      token.value = '';
      user.value = null;
      yield authLogoutSSO();
      removeCookie(PingCookie);
      removeCookie(InternalCookie);
      removeCookie(AuthToken);
      var {
        source_uri
      } = route.query || {};
      var redirect = fromArray(source_uri);
      var name = redirect || route.path || '';
      if (!name.includes('login')) {
        ctx.redirect(loginRouter(name));
      }
    });
    return _logout.apply(this, arguments);
  }
  return {
    sso,
    token,
    authSSO,
    setUserToken,
    getUserToken,
    getCleanToken,
    updateTokenSSO,
    fetchUser,
    logout,
    user,
    loggedIn,
    ssoLoading
  };
});
export default useUserStore;