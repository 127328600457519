import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import Vue from 'vue';
import { watchOnce } from '@vueuse/core';
import YandexMetrika from 'vue-yandex-metrika';
import GTag from 'vue-gtag';
import { YandexMetricConfig, GoogleMetricConfig } from '~/configs/metricsConfig';
import useSettingsStore, { MetricsService } from '~/stores/settingsStore';
var matricsHandler = {
  [MetricsService.Yandex]: app => {
    Vue.use(YandexMetrika, _objectSpread({
      router: app.router,
      env: process.env.NODE_ENV
    }, YandexMetricConfig));
  },
  [MetricsService.Google]: app => {
    Vue.use(GTag, _objectSpread({
      enabled: process.env.NODE_ENV !== 'development'
    }, GoogleMetricConfig), app.router);
  }
};
export default defineNuxtPlugin(nuxtApp => {
  var {
    app
  } = nuxtApp.nuxt2Context;
  var store = useSettingsStore();
  if (store.metricsService !== MetricsService.None) {
    matricsHandler[useSettingsStore().metricsService](app);
  } else {
    watchOnce(() => store.metricsService, () => {
      if (store.metricsService !== MetricsService.None) {
        matricsHandler[useSettingsStore().metricsService](app);
      }
    });
  }
});