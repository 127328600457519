import { useLocalStorage } from '@vueuse/core';
var App = {
  i18n: {}
};
export var locale = useLocalStorage('userLocale', 'en');
export default defineNuxtPlugin(nuxtApp => {
  var ctx = nuxtApp.nuxt2Context;
  App.i18n = ctx.app.i18n;
  ctx.app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    locale.value = newLocale;
  };
  var queryLocale = ctx.route.query.locale;
  if (queryLocale) {
    ctx.app.i18n.setLocale(queryLocale);
  }
});

// TODO remove
export { App };
export function useI18n() {
  return App.i18n;
}