import _asyncToGenerator from "@babel/runtime/helpers/esm/asyncToGenerator";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { stringify } from 'qs';
import { isNullish } from '@frontend/ui-kit';
import { createFetchClient } from '~/assets/utils/useFetch';
export var Method;
(function (Method) {
  Method["POST"] = "post";
  Method["GET"] = "get";
  Method["DELETE"] = "delete";
  Method["PUT"] = "put";
  Method["HEAD"] = "head";
})(Method || (Method = {}));
export var ParseMethod;
(function (ParseMethod) {
  ParseMethod["JSON"] = "json";
  ParseMethod["TEXT"] = "text";
  ParseMethod["BLOB"] = "blob";
})(ParseMethod || (ParseMethod = {}));
function trimSlashes(path) {
  return path.replace(/(^\/+|\/+$)/g, '');
}
function getRequestQuery(params) {
  return stringify(params, {
    arrayFormat: 'repeat',
    addQueryPrefix: true,
    filter: (_prefix, value) => isNullish(value) ? undefined : value
  });
}
function addBody(fetchConfig, body) {
  var _body$constructor;
  if (((_body$constructor = body.constructor) === null || _body$constructor === void 0 ? void 0 : _body$constructor.name) !== 'FormData') {
    fetchConfig.body = JSON.stringify(body);
    fetchConfig.headers = _objectSpread(_objectSpread({}, fetchConfig.headers || {}), {}, {
      'Content-Type': 'application/json'
    });
  } else {
    fetchConfig.body = body;
  }
}
export default function useApiClient(baseUrl) {
  var fetchClient = createFetchClient(baseUrl);
  function request(_x) {
    return _request.apply(this, arguments);
  }
  function _request() {
    _request = _asyncToGenerator(function* (url) {
      var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Method.GET;
      var body = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var config = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      var parseMethod = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : ParseMethod.JSON;
      if (params) url += getRequestQuery(params);
      var fetchConfig = _objectSpread({
        method
      }, config);
      if (body) addBody(fetchConfig, body);
      var {
        data,
        error
      } = yield fetchClient(url, fetchConfig)[parseMethod]();
      if (error.value) {
        throw new Error(data.value.message);
      }
      return data.value;
    });
    return _request.apply(this, arguments);
  }
  function requestNew(url) {
    var {
      method = Method.GET,
      body = null,
      params = undefined,
      config = {},
      immediate = true,
      initialData = undefined,
      parseMethod = ParseMethod.JSON,
      afterFetch
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (params) url = unref(url) + getRequestQuery(params);
    var fetchConfig = _objectSpread({
      method
    }, config);
    if (body) addBody(fetchConfig, body);
    return fetchClient(url, fetchConfig, {
      immediate,
      initialData,
      afterFetch
    })[parseMethod]();
  }
  function createResource(name) {
    var customMethodsFactory = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
    var resourcePath = trimSlashes(name);
    var create = data => request("".concat(resourcePath, "/"), Method.POST, data || {});
    var list = search => request("".concat(resourcePath, "/"), Method.GET, false, search);
    var get = id => request("".concat(resourcePath, "/").concat(id), Method.GET);
    var update = (id, data) => request("".concat(resourcePath, "/").concat(id), Method.PUT, data);
    var deleteMethod = id => request("".concat(resourcePath, "/").concat(id), Method.DELETE);
    var search = query => request("".concat(resourcePath, "/search"), Method.GET, false, query);
    var suggest = query => request("".concat(resourcePath, "/suggest"), Method.GET, false, query);
    var importMethod = form_data => request("".concat(resourcePath, "/import"), Method.POST, form_data);
    var importPreview = form_data => request("".concat(resourcePath, "/import/preview"), Method.POST, form_data);
    var display = function display() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
      return request("".concat(resourcePath, "/").concat(id, "/display"), Method.GET, false, query);
    };
    var display_list = query => request("".concat(resourcePath, "/display"), Method.GET, false, query);
    var customMethods = typeof customMethodsFactory === 'function' ? customMethodsFactory(request, resourcePath, requestNew) : {};
    return _objectSpread({
      create,
      list,
      get,
      update,
      delete: deleteMethod,
      search,
      suggest,
      import: importMethod,
      importPreview,
      display,
      display_list
    }, customMethods);
  }
  return {
    baseUrl,
    createResource,
    _request: request,
    _requestNew: requestNew
  };
}