import { onActivated, toRefs } from 'vue-demi';
import { useRoute } from 'vue-router/composables';
import useAppStore from '~/stores/appStore';
import useRecentPagesStore from '~/stores/recentPagesStore';
export function getBackLink(routePath) {
  var path = routePath.split('/');
  if (!path.pop()) {
    path.pop();
  }
  return path.join('/');
}
function getFullPageOption(value) {
  if (value === undefined) return {
    desktop: false,
    mobile: false
  };
  if (typeof value === 'object') return value;
  return {
    desktop: value,
    mobile: value
  };
}
export default function usePageOptions(options) {
  var appStore = useAppStore();
  var route = useRoute();
  var resentPagesStore = useRecentPagesStore();
  var {
    title,
    defaultPadding,
    fixedHeader,
    fullPage,
    backLink,
    breadcrumbs,
    titleShadow,
    darkBackground,
    headerTeleportId,
    mobileHeaderBorder
  } = toRefs(appStore);
  onActivated(() => {
    title.value = options.title;
    defaultPadding.value = options.defaultPadding === undefined ? true : options.defaultPadding;
    fixedHeader.value = options.fixedHeader === undefined ? false : options.fixedHeader;
    fullPage.value = getFullPageOption(options.fullPage);
    breadcrumbs.value = options.breadcrumbs || [];
    titleShadow.value = options.titleShadow || false;
    darkBackground.value = options.darkBackground || false;
    headerTeleportId.value = options.teleportId || route.name || '';
    var showBack = options.showBack === undefined ? true : options.showBack;
    backLink.value = showBack ? options.backLink || getBackLink(route.path) : '';
    mobileHeaderBorder.value = Boolean(options.mobileHeaderBorder);
    resentPagesStore.addItem({
      name: title.value,
      route: route.path,
      breadcrumbs: appStore.breadcrumbs,
      timestamp: new Date()
    });
  });
  watch(() => options.title, () => {
    title.value = options.title;
  });
  return {
    options,
    backLink
  };
}